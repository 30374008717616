import React from 'react'
import { Link } from 'react-router-dom'
import secureBtn from '../../assets/images/secure-btn.svg'
// import heroVideo from '../../assets/video/hero.mp4'
import newHeroImage from "../../assets/images/banner.gif"

const Hero = () => {
    return (
        <div className='hero py-10 bg-[#000000]'>
            <div className="container">
                <div className="flex lg:flex-row flex-col justify-between items-center">
                    <div className="lg:w-1/2 w-full">
                        <h1 className='text-white mb-0 text-[40px] lg:text-[74px] italic font-bold'>
                            Securing Web3
                        </h1>
                        <h2 className='text-[30px] mt-0 lg:text-[53px] italic text-[#FED506] font-normal'>
                            OnChain & OffChain
                        </h2>
                        <h3 className='text-sm lg:text-[27px] text-[#FFFFFFB2] font-light'>
                            with Advanced AI Capabilities
                        </h3>

                        <Link to={'/contact'} className='mt-4 block'>
                            <img src={secureBtn} className='secure-btn relative mt-10' alt="" />
                        </Link>
                    </div>
                    <div className="w-full lg:w-1/2">
                        {/* <video src={heroVideo} autoPlay muted loop></video> */}
                        <img src={newHeroImage} className='md:scale-110' alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero