import React from "react";
import errorImg from "../../assets/images/404.svg";

const Error = () => {
  return (
    <div className="bg-black py-10">
      <div className="container mx-auto">
        <img src={errorImg} className="w-1/3 block mx-auto" alt="" />
      </div>
    </div>
  );
};

export default Error;
