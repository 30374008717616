import React from 'react'

const Contact = () => {
    return (
        <div className='py-20 bg-black'>
            <div className="container">
                <div className="text-center">
                    <h2 className='text-[40px] text-white font-bold mb-20'>Contact Us</h2>
                </div>
                <form action="" method="post" className='xl:max-w-5xl w-full mx-auto'>
                    <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-10 gap-5">
                        <div className="item">
                            <label htmlFor="name" className='text-white'>
                                Name
                            </label>
                            <input type="text" name='name' id='name' className='w-full py-3 bg-black border-b border-[#ffffff7b] text-white outline-none' />
                        </div>
                        <div className="item">
                            <label htmlFor="email" className='text-white'>
                                Email
                            </label>
                            <input type="email" name='email' id='email' className='w-full py-3 bg-black border-b border-[#ffffff7b] text-white outline-none' />
                        </div>
                        <div className="item">
                            <label htmlFor="phone" className='text-white'>
                                Phone
                            </label>
                            <input type="tel" name='phone' id='phone' className='w-full py-3 bg-black border-b border-[#ffffff7b] text-white outline-none' />
                        </div>
                        <div className="item">
                            <label htmlFor="service" className='text-white'>
                                The Service You Are Interested In
                            </label>
                            <select name="service" id="service" className='w-full py-3 bg-black border-b border-[#ffffff7b] text-white outline-none'>
                                <option value="0" selected disabled>Choose 1</option>
                                <option value="1">⁠Consulting</option>
                                <option value="2">⁠API</option>
                                <option value="3">⁠⁠Partnership</option>
                                <option value="4">⁠⁠Investment</option>
                                <option value="5">⁠⁠Other</option>
                            </select>
                        </div>
                        <div className="item lg:col-span-2">
                            <label htmlFor="message" className='text-white'>
                                Message
                            </label>
                            <textarea name="message" id="message" cols="30" rows="5" className='w-full py-3 bg-black border-b border-[#ffffff7b] text-white outline-none'></textarea>
                        </div>
                        <div className="item text-right">
                            <button type='submit' className='bg-[#28B2E3] text-black py-3 px-10 font-bold text-xl rounded-full'>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contact