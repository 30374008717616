import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const FeatureCard = ({ value, extension, desc, img, color }) => {
  const { ref, inView } = useInView({
    threshold: 0.3, // Percentage of the component in the viewport to trigger
    triggerOnce: true, // Only trigger once
  });
  return (
    <div ref={ref} className={`feature-card border border-[${color}]`}>
      <div className="flex justify-between items-center md:text-left text-center gap-4 md:p-6 p-2">
        <div className="left">
          <h2 className="md:text-[40px] text-[25px] font-[600]">
            {inView && <CountUp end={value} />}
            {extension}
          </h2>
          <p className="mt-3 text-[#FFFFFFB2] md:text-[16px] text-xs">{desc}</p>
        </div>
        <div className="right md:block hidden">
          <img src={img} className="min-h-[150px]" alt="" />
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
