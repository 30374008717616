import React from "react";
// import bgImg from "../../assets/images/api-offerings.png";
import ApiCard from "../api-card";
import PricingCard from "../pricing-card";
const APIOfferings = () => {
  const apiData = [
    {
      name: "Phishing Detection",
      desc: "This API identifies and flags potentially fraudulent accounts based on transaction patterns and other indicators.",
      availability: 95,
      latency: 3568,
      score: 8.7,
      link: "/",
      status: 1,
      packages: [
        {
          name: "Freelancer",
          desc: "The essentials to provide your best work for clients.",
          price: 24,
          features: [
            "5 Products",
            "Up to 1,000 subscribers",
            "Basic analytics",
            "48-hour support response time",
          ],
        },
        {
          name: "Startup",
          desc: "A Plan that scales with your rapidly growing business.",
          price: 32,
          features: [
            "25 Products",
            "Up to 10,000 subscribers",
            "Advanced analytics",
            "24-hour support response time",
            "Marketing automations",
          ],
        },
        {
          name: "Enterprise",
          desc: "Dedicated Support and Infrastructure for your company.",
          price: 48,
          features: [
            "Unlimited Products",
            "Unlimited subscribers",
            "Advanced analytics",
            "1-hour, dedicated support response time",
            "Marketing automations",
          ],
        },
      ],
    },
    {
      name: "Anomaly Detection",
      desc: "This API retrieves detailed transaction history for accounts, supporting various filters and sorting options.",
      availability: 85,
      latency: 2054,
      score: 9.2,
      link: "/",
      status: 0,
      packages: [
        {
          name: "Freelancer",
          desc: "The essentials to provide your best work for clients.",
          price: 24,
          features: [
            "5 Products",
            "Up to 1,000 subscribers",
            "Basic analytics",
            "48-hour support response time",
          ],
        },
        {
          name: "Startup",
          desc: "A Plan that scales with your rapidly growing business.",
          price: 32,
          features: [
            "25 Products",
            "Up to 10,000 subscribers",
            "Advanced analytics",
            "24-hour support response time",
            "Marketing automations",
          ],
        },
        {
          name: "Enterprise",
          desc: "Dedicated Support and Infrastructure for your company.",
          price: 48,
          features: [
            "Unlimited Products",
            "Unlimited subscribers",
            "Advanced analytics",
            "1-hour, dedicated support response time",
            "Marketing automations",
          ],
        },
      ],
    },
    {
      name: "Embedding System",
      desc: "This API provides embedding vectors for transaction data, allowing for efficient similarity searches and machine learning applications.",
      availability: 90,
      latency: 2557,
      score: 9.9,
      link: "/",
      status: 0,
      packages: [
        {
          name: "Freelancer",
          desc: "The essentials to provide your best work for clients.",
          price: 24,
          features: [
            "5 Products",
            "Up to 1,000 subscribers",
            "Basic analytics",
            "48-hour support response time",
          ],
        },
        {
          name: "Startup",
          desc: "A Plan that scales with your rapidly growing business.",
          price: 32,
          features: [
            "25 Products",
            "Up to 10,000 subscribers",
            "Advanced analytics",
            "24-hour support response time",
            "Marketing automations",
          ],
        },
        {
          name: "Enterprise",
          desc: "Dedicated Support and Infrastructure for your company.",
          price: 48,
          features: [
            "Unlimited Products",
            "Unlimited subscribers",
            "Advanced analytics",
            "1-hour, dedicated support response time",
            "Marketing automations",
          ],
        },
      ],
    },
  ];
  return (
    <div className="api-offerings py-10 bg-[#03151C]">
      {/* <img
        src={bgImg}
        className="absolute top-0 left-0 w-full h-auto z-10"
        alt=""
      /> */}
      <div className="container relative z-20">
        <div className="text-center">
          <h2 className="text-[40px] text-white font-[300]">
            Our API Offerings
          </h2>
          <p className="text-white">Road Map To Safety Blockchain Security:</p>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 mt-10">
          {apiData.map((api, index) => (
            <ApiCard
              key={index}
              name={api.name}
              desc={api.desc}
              availability={api.availability}
              status={api.status}
              latency={api.latency}
              score={api.score}
            />
          ))}
        </div>

        <div className="text-start mt-10">
          <div className="card pricing-card p-5 relative">
            <div className="overlay-glass">
              <h2 className="text-white text-[30px] font-normal">
                To Be Anounced
              </h2>
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[24px]">
              {apiData[0].packages.map((pack, index) => (
                <PricingCard key={index} pack={pack} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APIOfferings;
