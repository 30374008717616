import React from "react";
import { Link } from "react-router-dom";
// import secure from "../../assets/images/future-bg.svg"
// import { Link } from 'react-router-dom'
import secureBtn from "../../assets/images/secure-btn.svg";
// import secureImg from "../../assets/images/secure-img.png"

const SecureFuture = () => {
  return (
    <div className="secure-future bg-[#020e12] py-40">
      <div className="container">
        <div className="text-center">
          <h2
            className="text-[40px] font-[300] text-[#FFFFFFB2]"
            style={{ lineHeight: 1 }}
          >
            Secure Your Future with Our <br /> Advanced Blockchain Protection
          </h2>
          <p className="text-white my-4 font-[300]">
            Explore our AI-powered solutions and experience the next level of{" "}
            <br /> protection. Get started today for a secure tomorrow!
          </p>

          <div className="flex justify-center">
            <Link to={"/contact"} className="mt-4 w-auto">
              <img
                src={secureBtn}
                className="secure-btn relative mt-10"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecureFuture;
