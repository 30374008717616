import React from "react";
import { FiArrowUpRight } from "react-icons/fi";

const ApiCard = ({ name, desc, availability, latency, score, status }) => {
  return (
    <div className={`api-card p-6 ${status === 1 && "active"}`}>
      <div className="flex flex-col justify-between items-start gap-3">
        <div className="flex w-full justify-between items items-center">
          <p className="font-[500] text-white">{name}</p>
          <FiArrowUpRight className="text-[#28B2E3] text-2xl" />
        </div>
        <p className="text-white text-sm">{desc}</p>

        <div className="flex md:flex-nowrap flex-wrap mt-2 justify-start align-center gap-3">
          <span className="badge-red text-xs inline-block px-2 py-1 rounded-full bg-[#E53C0E] text-white">
            Availability: {availability}%
          </span>
          <span className="badge-red text-xs inline-block px-2 py-1 rounded-full bg-[#2ABA21] text-white">
            Latency: {latency}ms
          </span>
          <span className="badge-red text-xs inline-block px-2 py-1 rounded-full bg-[#1E4DCC] text-white">
            Score: {score}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ApiCard;
