import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../assets/images/logo.svg"
import x from "../../assets/images/x.svg"
import telegram from "../../assets/images/telegram.svg"
import lin from "../../assets/images/in.svg"
import mail from "../../assets/images/mail.png"

const Footer = () => {
    return (
        <footer className='footer pt-10 pb-5 border-t border-[#4A4848B2] bg-black'>
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mb-24">
                    <div className="item">
                        <p className="text-white font-bold mb-3">
                            About Prismblocks
                        </p>
                        <p className="font-light text-[#ffffff7b] text-sm">
                            Empowering clients with AI by proactively detect and prevent criminal activities, ensuring the highest level of security and integrity before transactions are approved on the blockchain.
                        </p>
                    </div>
                    <div className="item">
                        <p className="text-white font-bold mb-3">
                            Quick Links
                        </p>
                        <ul className='flex flex-col gap-3'>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    Our Team
                                </Link>
                            </li>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    News & Events
                                </Link>
                            </li>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="item">
                        <p className="text-white font-bold mb-3">
                            Resources
                        </p>
                        <ul className='flex flex-col gap-3'>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    White paper
                                </Link>
                            </li>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    The Deck
                                </Link>
                            </li>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    The Tokenomics
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="item">
                        <p className="text-white font-bold mb-3">
                            Legal
                        </p>
                        <ul className='flex flex-col gap-3'>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to={'/'} className='text-[#ffffff7b] text-sm block'>
                                    Terms of Use
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="border-t border-[#4A4848B2]"></div>
                <div className="grid mt-8 grid-cols-1 lg:grid-cols-3 gap-4 text-center">
                    <Link to={'/'} className='text-white text-sm f-center'>
                        <img src={logo} className='f-logo' alt="" />
                    </Link>
                    <div className="text-center my-auto">
                        <p className="text-sm text-[#ffffff7b]">
                            © 2024 PrismBlocks. All rights reserved.
                        </p>
                    </div>
                    <div className="flex justify-center lg:justify-end items-center gap-3">
                        <Link to={'/'} className='text-white text-sm'>
                            <img src={x} className='w-[35px]' alt="" />
                        </Link>
                        <Link to={'/'} className='text-white text-sm'>
                            <img src={telegram} className='w-[30px]' alt="" />
                        </Link>
                        <Link to={'/'} className='text-white text-sm'>
                            <img src={lin} className='w-[30px]' alt="" />
                        </Link>
                        <Link to={'mailto:office@prismblocks.com'} className='text-white text-sm'>
                            <img src={mail} className='w-[25px] ml-1' alt="" />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer