import React from 'react'

const TeamCard = ({ team, modalHandler, setSelectedTeam }) => {
    return (
        <div className='team-card bg-[#FFFFFF12] py-[32px] px-[16px] text-center' onClick={() => {
            setSelectedTeam(team)
            modalHandler(team.id)
        }}>
            <img src={team.img} className='block mx-auto mb-3' alt="" />
            <h5 className='text-lg text-white font-semibold'>
                {team.name}
            </h5>
            <svg className='block mx-auto my-3' width="31" height="2" viewBox="0 0 31 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.537476" y1="1.17969" x2="30.9574" y2="1.17969" stroke="white" />
            </svg>
            {team.designation}
        </div>
    )
}

export default TeamCard