import React from "react";
// import blogImg from "../../assets/images/blog-img.png";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

// blogs images
import blog1 from "../../assets/images/blog-banner/1.jpg";
import blog2 from "../../assets/images/blog-banner/2.jpg";
import blog3 from "../../assets/images/blog-banner/3.jpg";
import blog4 from "../../assets/images/blog-banner/4.jpg";
import blog5 from "../../assets/images/blog-banner/5.png";
import blog6 from "../../assets/images/blog-banner/6.jpg";
import blog7 from "../../assets/images/blog-banner/7.jpg";
import blog8 from "../../assets/images/blog-banner/8.png";
import blog9 from "../../assets/images/blog-banner/9.jpg";
import blog10 from "../../assets/images/blog-banner/10.jpg";

const NewsResources = () => {
  const [currentPagination, setCurrentPagination] = React.useState(1);
  const allBlogs = [
    {
      id: 1,
      title: "The Rise of Phishing Attacks in Web3 Security",
      published: "15 October, 2024",
      img: blog1,
      short_desc:
        " Phishing, a form of social engineering attack, tricks individuals into revealing sensitive information such as private keys or passwords by impersonating legitimate entities.",
    },
    {
      id: 2,
      title: "Q3 2024 Blockchain Hack Report",
      published: "19 October, 2024",
      img: blog2,
      short_desc:
        " Phishing, a form of social engineering attack, tricks individuals into revealing sensitive information such as private keys or passwords by impersonating legitimate entities.",
    },
    {
      id: 3,
      title: "October 2024 Hack Report",
      published: "11 November, 2024",
      img: blog3,
      short_desc:
        "October 2024 was marked by significant security breaches, with crypto asset losses totaling $126.93 million. The incidents targeted both centralized exchanges and decentralized platforms",
    },
    {
      id: 4,
      title:
        "Protecting Web3 from Human Exploits: Top Social Engineering Tactics",
      published: "23 November, 2024",
      img: blog4,
      short_desc:
        "As Web3 grows, attackers exploit human psychology through social engineering tactics like phishing, baiting, and impersonation to gain access to sensitive data.",
    },
    {
      id: 5,
      title: "What is Liquid Staking? How does it work?",
      published: "28 November, 2024",
      img: blog5,
      short_desc:
        "Liquid staking enables users to stake ETH without locking funds or running nodes. Platforms like Lido offer liquid staking tokens (LSTs) to represent staked assets and rewards.",
    },
    {
      id: 6,
      title: "Blockchain Security Review: August 2024",
      published: "29 November, 2024",
      img: blog6,
      short_desc:
        "August 2024 saw over $313.86M in losses due to phishing scams, cross-chain vulnerabilities, and protocol-specific exploits, highlighting the urgent need for stronger blockchain security.",
    },
    {
      id: 7,
      title: "7 Game-Changing Crypto Wallet Security Tips for 2024-25",
      published: "03 December, 2024",
      img: blog7,
      short_desc:
        "Protect your cryptocurrency wallet with these essential tips for 2024-25. Learn how to use cold storage, prevent phishing scams, secure seed phrases, and more to safeguard your digital assets.",
    },
    {
      id: 8,
      title:
        "Smart Contract Security: Common Vulnerabilities and Their Lasting Presence",
      published: "04 December, 2024",
      img: blog8,
      short_desc:
        "Smart contracts have revolutionized blockchain transactions but are still prone to vulnerabilities. Learn about common risks like reentrancy attacks, authentication issues, and phishing threats.",
    },
    {
      id: 9,
      title: "AI in Cybersecurity: Revolutionizing Digital Defense",
      published: "06 December, 2024",
      img: blog9,
      short_desc:
        "AI is transforming cybersecurity by improving threat detection, automation, and fraud prevention. Learn how AI is reshaping digital defense and its role in safeguarding online assets.",
    },
    {
      id: 10,
      title:
        "Rising Clipper Malware Attacks: A Growing Threat to Cryptocurrency Users",
      published: "10 December, 2024",
      img: blog10,
      short_desc:
        "Clipper Malware is surging, silently redirecting funds to attackers. Learn how it works and how to protect your cryptocurrency from this growing threat.",
    },
  ];
  const [blogs, setBlogs] = React.useState(allBlogs);
  return (
    <div className="bg-[#070c0d] py-[60px]">
      <div className="container">
        <h2 className="text-[32px] font-bold italic text-[#FFFFFFB2]">
          News & Resources
        </h2>
        <p className="text-[#FFFFFFB2] mb-7">
          Here you will get updated, fine and OG - articles, papers, studies and
          reports.
        </p>

        <form action="">
          <input
            type="text"
            className="w-full search-input ps-14 py-3 rounded border border-[#FFFFFF66] bg-[#1D2526] text-white"
            placeholder="Search"
            onChange={(e) => {
              setBlogs(
                allBlogs.filter((blog) =>
                  blog.title.toLowerCase().includes(e.target.value)
                )
              );
            }}
            name=""
            id=""
          />
        </form>

        <div className="blogs mt-10 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[24px]">
          {blogs
            .slice((currentPagination - 1) * 8, currentPagination * 8)
            .map((blog, index) => (
              <div
                key={index}
                className="blog-card overflow-hidden bg-[#1D2526] rounded-lg"
              >
                <div className="img-holder relative">
                  <img
                    src={blog.img}
                    alt=""
                    className="w-full h-[200px] object-cover mb-5"
                  />
                  <p className="text-[#FED506] published-date bg-[#000000] text-sm mb-2 inline-flex px-4 py-2 justify-start items-center gap-2">
                    <FaCalendarAlt className="text-[#FED506]" />
                    {blog.published}
                  </p>
                </div>
                <div className="p-5">
                  <h3 className="text-[#FFFFFFB2] text-lg font-bold mb-2">
                    {blog.title}
                  </h3>

                  <p className="text-[#FFFFFF66] text-sm mb-2">
                    {blog.short_desc}
                  </p>
                  <Link
                    to={{
                      pathname: `/news-resources/${blog.id}`,
                      state: { blog },
                    }}
                    className="text-[#28B2E3] text-sm"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
        </div>

        {/* pagination */}
        <section className="pagination mt-10 flex justify-between items-center">
          <button
            className="btn-pagination text-white flex justify-start items-center gap-2"
            onClick={() => {
              if (currentPagination > 1) {
                setCurrentPagination(currentPagination - 1);
              }
            }}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.4784 10.2122H4.52441M4.52441 10.2122L10.5014 16.0456M4.52441 10.2122L10.5014 4.37891"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Previous</span>
          </button>
          <div className="flex gap-2 text-white">
            {/* <button className="btn-pagination w-[40px] h-[40px] bg-[#FFFFFF12] rounded-full hover:bg-white hover:text-black">
              1
            </button>
            <button className="btn-pagination w-[40px] h-[40px] bg-[#FFFFFF12] rounded-full hover:bg-white hover:text-black">
              2
            </button>
            <button className="btn-pagination w-[40px] h-[40px] bg-[#FFFFFF12] rounded-full hover:bg-white hover:text-black">
              3
            </button>
            <button className="btn-pagination w-[40px] h-[40px] bg-[#FFFFFF12] rounded-full hover:bg-white hover:text-black">
              4
            </button>
            <button className="btn-pagination w-[40px] h-[40px] bg-[#FFFFFF12] rounded-full hover:bg-white hover:text-black">
              5
            </button> */}
            {Array.from({ length: Math.ceil(blogs.length / 8) }, (_, i) => (
              <button
                key={i}
                className={`btn-pagination w-[40px] h-[40px] bg-[#FFFFFF12] rounded-full hover:bg-white hover:text-black ${
                  currentPagination === i + 1 ? "bg-white text-black" : ""
                }`}
                onClick={() => setCurrentPagination(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
          <button
            className="btn-pagination text-white flex justify-end items-center gap-2"
            onClick={() => {
              if (currentPagination < Math.ceil(blogs.length / 8)) {
                setCurrentPagination(currentPagination + 1);
              }
            }}
          >
            <span>Next</span>
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.52424 10.2122H16.4783M16.4783 10.2122L10.5013 16.0456M16.4783 10.2122L10.5013 4.37891"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </section>
        {/* pagination */}
      </div>
    </div>
  );
};

export default NewsResources;
