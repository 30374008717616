import React from 'react'

const DifferentiateCard = ({logo, title, description}) => {
    return (
        <div className='diff-card px-10 py-10 flex flex-col md:justify-start justify-center md:items-start items-center md:text-start text-center md:gap-3'>
            <img src={logo} className='mb-4' alt="" />
            <h2 className='text-[20px] text-white font-normal text-center'>
                {title}
            </h2>
            <p className='text-[#FFFFFFB2] md:text-start text-center'>
                {description}
            </p>
        </div>
    )
}

export default DifferentiateCard