import React from 'react'

const SolutionCard = ({ img, title, subtitle }) => {
    return (
        <div className='flex gap-4 mb-10 justify-start items-center'>
            <div className="img">
                <img src={img} alt="" />
            </div>
            <div className="content">
                <h2 className='text-[#ffffff] text-[20px] font-[500]'>
                    {title}
                </h2>
                <p className='text-[#FFFFFFB2]'>
                    {subtitle}
                </p>
            </div>
        </div>
    )
}

export default SolutionCard