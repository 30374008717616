import React from 'react'
import leftImg from "../../assets/images/animated.gif"
import i1 from "../../assets/images/s1.svg"
import i2 from "../../assets/images/s2.svg"
import i3 from "../../assets/images/s3.svg"
import SolutionCard from '../solution-card'
import secureBtn from "../../assets/images/secure-btn.svg"
import { Link } from 'react-router-dom'

const Solution = () => {
    const solutions = [
        {
            id: 1,
            title: "We Monitor",
            subtitle: "Constant vigilance for blockchain",
            img: i1
        },
        {
            id: 2,
            title: "We Detect",
            subtitle: "Identifying vulnerabilities and threats swiftly",
            img: i2
        },
        {
            id: 3,
            title: "We Protect",
            subtitle: "Proactively defending our users",
            img: i3
        },
    ]
    return (
        <div className='solution py-10 bg-black'>
            <div className="container">
                <div className="text-center">
                    <h2 className='text-[40px] text-[#FFFFFFB2]'>Our Solution</h2>
                </div>

                <div className="flex lg:flex-row flex-col mt-14 justify-between items-center gap-10">
                    <div className="left w-full lg:w-1/2">
                        <img src={leftImg} className='mx-auto block' alt="" />
                    </div>
                    <div className="right w-full lg:w-1/2">
                        {
                            solutions.map((solution, index) => (
                                <SolutionCard key={index} {...solution} />
                            ))
                        }

                        <Link to={'/contact'} className='mt-4 block'>
                            <img src={secureBtn} className='secure-btn relative mt-4' alt="" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Solution