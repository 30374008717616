import React from "react";
import ValueCard from "../value-card";
import icon1 from "../../assets/images/i1.png";
import icon2 from "../../assets/images/i2.png";
import icon3 from "../../assets/images/i3.png";

const Values = () => {
  const values = [
    {
      id: 1,
      value: 2100,
      icon: icon1,
      extension: "+",
      color: "#DE0202",
      title: "Critical exploits detected",
    },
    {
      id: 2,
      value: 126,
      icon: icon2,
      extension: "M+",
      color: "#32BE71",
      title: "We secured on protocols",
    },
    {
      id: 3,
      value: 3,
      icon: icon3,
      extension: "B+",
      color: "#28B2E3",
      title: "Total value monitored",
    },
  ];
  return (
    <div className="values py-10 bg-black text-white">
      <div className="container">
        <div className="grid lg:grid-cols-3 grid-cols-3 lg:gap-4 gap-3 items-start"  data-aos="fade-in">
          {values.map((value, index) => (
            <ValueCard key={index} {...value} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Values;
