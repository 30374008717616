import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { FaBars } from "react-icons/fa";

const Header = () => {
  // if the screen width is less than 992px, the navbar will be hidden
  return (
    <div className="header py-4">
      <div className="container">
        <nav className="w-full">
          <div className="flex lg:flex-row flex-col justify-between items-center w-full">
            <div className="flex lg:w-auto w-full justify-between items-center">
              <Link to={"/"}>
                <img src={logo} className="lg:w-[45px] w-[30px]" alt="" />
              </Link>

              <button
                className="btn nav-toggler-btn lg:hidden block"
                onClick={() => {
                  document.getElementById("menus").classList.toggle("active");
                }}
              >
                <FaBars className="text-white" />
              </button>
            </div>

            <div
              id="menus"
              className="flex menus w-full lg:flex-row flex-col justify-end items-center lg:gap-8 gap-[10px] lg:mt-0 mt-8"
            >
              <NavLink
                to={"/"}
                className="text-white relative nav-link text-sm"
                onClick={() => {
                  document.getElementById("menus").classList.toggle("active");
                }}
              >
                <span className="relative x-20">Home</span>
              </NavLink>
              {/* <NavLink
                to={"/"}
                className="text-white relative nav-link text-sm"
                onClick={() => {
                  document.getElementById("menus").classList.toggle("active");
                }}
              >
                <span className="relative x-20 flex justify-center items-center gap-2">
                  Bug Bounty <FaCaretDown className="text-white" />
                </span>
              </NavLink> */}
              <NavLink
                to={"/team"}
                className="text-white relative nav-link text-sm"
                onClick={() => {
                  document.getElementById("menus").classList.toggle("active");
                }}
              >
                <span className="relative x-20">Our Team</span>
              </NavLink>
              <NavLink
                to={"/news-resources"}
                className="text-white relative nav-link text-sm"
                onClick={() => {
                  document.getElementById("menus").classList.toggle("active");
                }}
              >
                <span className="relative x-20">News & Resources</span>
              </NavLink>
              <NavLink
                to={"/apis"}
                className="text-white relative nav-link text-sm"
                onClick={() => {
                  document.getElementById("menus").classList.toggle("active");
                }}
              >
                <span className="relative x-20">APIs
                  <span className="bubble">soon</span>
                </span>
              </NavLink>
              <NavLink
                to={"/contact"}
                className="text-white relative nav-link text-sm"
                onClick={() => {
                  document.getElementById("menus").classList.toggle("active");
                }}
              >
                <span className="relative x-20">Contact Us</span>
              </NavLink>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
