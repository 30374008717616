import React from "react";
import img1 from "../../assets/images/f1.svg";
import img2 from "../../assets/images/f2.svg";
import img3 from "../../assets/images/f3.svg";
import FeatureCard from "../feature-card";

const Feature = () => {
  const features = [
    {
      id: 1,
      value: 7,
      extension: "B+",
      desc: "wallets got scammed last 5 years",
      img: img1,
      color: "#32BE71",
    },
    {
      id: 2,
      value: 15,
      extension: "B$+",
      desc: "Financially loss in the last 3 years alone",
      img: img2,
      color: "#28B2E3",
    },
    {
      id: 3,
      value: 8,
      extension: "+",
      desc: "More then 5 major exploits in a week",
      img: img3,
      color: "#FED506",
    },
  ];
  return (
    <div className="feature bg-black text-white py-10">
      <div className="container">
        <div className="features grid lg:grid-cols-3 grid-cols-3 gap-5" data-aos="fade-in">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feature;
