import React, { useState } from "react";
import { Link } from "react-router-dom";
import contactBtn from "../../assets/images/contact.png";

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What Are The Benefits Of Using Prism Blocks?",
      answer:
        "Prism Blocks uses an AI-based engine to detect and modify vulnerabilities, exploits, and fraud. They also use a data-driven methodology, ADASYN for imbalanced datasets, and tree-based ensemble models.",
    },
    {
      question: "How Does Prism Blocks Work?",
      answer: "Details about how Prism Blocks works go here.",
    },
    {
      question: "How Much Does Prism Blocks Cost?",
      answer: "Details about pricing go here.",
    },
    {
      question: "How Long Does It Take To Implement Prism Blocks?",
      answer: "Details about implementation time go here.",
    },
    {
      question: "What Kind Of Support Is Available For Prism Blocks?",
      answer: "Details about support go here.",
    },
  ];

  return (
    <div className="bg-black py-16">
      <div className="container">
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 gap-10">
          <div className="col-span-1">
            <h2 className="text-start text-[40px] font-normal mb-2 text-gray-200">
              FAQ
            </h2>
            <p className="text-xl font-light text-white mb-5">
              Have more questions? Send us a message!
            </p>
            <Link to={"/contact"} className="mt-4 block">
              <img
                src={contactBtn}
                className="secure-btn relative mt-10"
                alt=""
              />
            </Link>
          </div>
          <div className="bg-transparent xl:col-span-3 lg:col-span-2 col-span-1 rounded-lg shadow-md border border-[#7E7E7E] p-5">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`${
                  index !== faqs.length - 1 ? "border-b border-[#7E7E7E]" : ""
                } ${activeIndex === index ? "bg-transparent" : ""}`}
              >
                <button
                  className="w-full py-5 flex items-center justify-between px-4 text-[#EFEFEF] text-left text-xl font-medium"
                  onClick={() => toggleAccordion(index)}
                >
                  <span>{faq.question}</span>
                  <span className="text-[#EFEFEF] text-2xl">
                    {activeIndex === index ? "-" : "+"}
                  </span>
                </button>
                {activeIndex === index && (
                  <div className="px-4 pb-4 text-gray-300 text-sm">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQAccordion;
