import React from 'react'
import DifferentiateCard from '../differentiate-card'
import d1 from "../../assets/images/d1.png"
import d2 from "../../assets/images/d2.png"
import d3 from "../../assets/images/d3.png"

const Differentiation = () => {
    const diffData = [
        {
            id: 1,
            logo: d1,
            title: "Security ",
            description: "Utilizing AI/ML to scan and analyze the activity."
        },
        {
            id: 2,
            logo: d2,
            title: "Adaptability Capabilities",
            description: "Formulating sophisticated learning mechanisms using embedding system and DL"
        },
        {
            id: 3,
            logo: d3,
            title: "Community Driven",
            description: "Security researchers are being incentivized to share data and boost the AI capabilities."
        },
    ]
    return (
        <div className='differentiation py-10 bg-[#03151C]'>
            <div className="container">
                <div className="text-center mb-[48px]">
                    <h2 className='text-[40px] text-[#FFFFFFB2]'>
                        Our Differentiation
                    </h2>
                </div>

                <div className="grid lg:grid-cols-3 grid-cols-1 gap-8">
                    {
                        diffData.map((data, index) => (
                            <DifferentiateCard key={index} {...data} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Differentiation