import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layouts/main.layout";
import Homepage from "../pages/home";
import Team from "../pages/team";
import NewsResources from "../pages/news-resources";
import BlogDetails from "../pages/blog-details";
import Error from "../pages/error";
import Contact from "../pages/contact";
import Soon from "../pages/soon";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/team",
        element: <Team />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/news-resources",
        element: <NewsResources />,
      },
      {
        path: "/apis",
        element: <Soon />,
      },
      {
        path: "/news-resources/:id",
        element: <BlogDetails />,
      },
      // error page
      {
        path: "*",
        element: <Error />,
      },
    ],
  },
]);
