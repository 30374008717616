import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const ValueCard = ({ icon, value, extension, color, title }) => {
  const { ref, inView } = useInView({
    threshold: 0.3, // Percentage of the component in the viewport to trigger
    triggerOnce: true, // Only trigger once
  });
  return (
    <div
      ref={ref}
      className="text-center flex justify-center items-center flex-col gap-2"
    >
      <img src={icon} alt="" />
      <h2
        className="lg:text-[40px] text-2xl font-[600]"
        style={{ color: color }}
      >
        {inView && <CountUp end={value} />}
        {extension}
      </h2>
      <p className="text-[#FFFFFFB2] lg:text-[16px] text-xs">{title}</p>
    </div>
  );
};

export default ValueCard;
