import React from "react";
import soonImg from "../../assets/images/soon.svg";

const Soon = () => {
  return (
    <div className="bg-black py-10">
      <div className="container mx-auto">
        <img src={soonImg} className="w-1/3 block mx-auto mb-5" alt="" />
        <h3 className="text-2xl font-bold text-white text-center">Coming Soon!!</h3>
      </div>
    </div>
  );
};

export default Soon;
