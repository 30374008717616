import React from "react";

import slide1 from "../../assets/images/partners/1.png";
import slide2 from "../../assets/images/partners/2.png";
import slide3 from "../../assets/images/partners/3.png";
import slide4 from "../../assets/images/partners/4.png";
import slide5 from "../../assets/images/partners/5.png";
import slide6 from "../../assets/images/partners/6.png";
import slide7 from "../../assets/images/partners/7.png";
import slide8 from "../../assets/images/partners/8.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
const TrustedBy = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 300,
    easing: "linear",
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="trustedby py-10 bg-black text-white">
      <div className="container">
        <h2 className="text-[40px] font-light text-[#FFFFFFB2] trusted-title">
          Trusted By
        </h2>

        <div className="slick-holder">
          <Slider {...settings}>
            <div className="text-center">
              <img src={slide1} alt="" />
            </div>
            <div>
              <img src={slide2} alt="" />
            </div>
            <div>
              <img src={slide3} alt="" />
            </div>
            <div>
              <img src={slide4} alt="" />
            </div>
            <div>
              <img src={slide5} alt="" />
            </div>
            <div>
              <img src={slide6} alt="" />
            </div>
            <div>
              <img src={slide7} alt="" />
            </div>
            <div>
              <Link to="https://www.fiverr.com/webdevmaruf">
                <img src={slide8} alt="" />
              </Link>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
