import React from "react";
import Hero from "../../components/hero";
import Feature from "../../components/feature";
import TrustedBy from "../../components/trusted-by";
import Values from "../../components/values";
import line from "../../assets/images/line.svg";
import Solution from "../../components/solution";
import Differentiation from "../../components/differentiation";
import SecureFuture from "../../components/secure-future";
// import Roadmap from '../../components/roadmap'
import APIOfferings from "../../components/api-offerings";
import FAQAccordion from "../../components/faq-accordion";

const Homepage = () => {
  return (
    <>
      <Hero />
      <img src={line} className="w-full bg-black" alt="" />
      <Feature />
      <img src={line} className="w-full bg-black" alt="" />
      <TrustedBy />
      <img src={line} className="w-full bg-black" alt="" />
      <Values />
      <img src={line} className="w-full bg-black" alt="" />
      <Solution />
      <img src={line} className="w-full bg-black" alt="" />
      <Differentiation />
      <img src={line} className="w-full bg-black" alt="" />
      <FAQAccordion />
      <img src={line} className="w-full bg-black" alt="" />
      {/* <Roadmap /> */}
      <SecureFuture />
      <img src={line} className="w-full bg-black" alt="" />
      <APIOfferings />
      <img src={line} className="w-full bg-black" alt="" />
    </>
  );
};

export default Homepage;
